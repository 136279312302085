.container-narrow {
    max-width: calc(1052rem / 16);
    margin-left: auto;
    margin-right: auto;
}

.page-wrapper {
    padding-top:70px;

    @media screen and (max-width: 767px) {
        padding-top:100px;
    }
    overflow-x:hidden;
}

.page-wrapper--full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}