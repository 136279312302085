.collapse-item {
    background: #F7F9FA;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        line-height: calc(26/16);
    }
}
.collapse-item__head {
    position: relative;
    font-size: calc(14rem / 16);
    line-height: calc(20/14);
    padding: calc(10rem / 16) calc(40rem / 16) calc(10rem / 16) calc(15rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-weight-rounded-light);
    width: 100%;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(24/16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(10rem / 16) calc(70rem / 16) calc(10rem / 16) calc(25rem / 16);
    }
}
.collapse-item__head__icon {
    position: absolute;
    right: calc(15rem / 16);
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    font-size: calc(8rem / 16);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 1200px) {
        right: calc(32rem / 16);
    }
}
.collapsed>.collapse-item__head__icon {
    transform: translateY(-50%);
}
/* close icon */
.collapse-item__head__icon.icon-close {
    transform: translateY(-50%);
}
.collapsed>.collapse-item__head__icon.icon-close {
    transform: translateY(-50%) rotate(-45deg);
}
.collapse-item__body {
    padding: calc(18rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(40rem / 16) calc(18rem / 16) calc(15rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(18rem / 16) calc(70rem / 16) calc(18rem / 16) calc(25rem / 16);
    }
}
.collapse-item + .collapse-item {
    margin-top: calc(4rem / 16);
}

/* large collapse */
.collapse-item--lg .collapse-item__head {
    padding: calc(10rem / 16) calc(40rem / 16) calc(10rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(45rem / 16) calc(10rem / 16) calc(35rem / 16);
    }
}
.collapse-item--lg .collapse-item__body {
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) calc(30rem / 16);
    }
}

/* styles */
.collapse-item--plain {
    background: transparent;
    border: 1px solid #E7E7E7;
}
.collapse-item--plain + .collapse-item--plain {
    margin-top: calc(20rem / 16);
}
.collapse-item--plain .collapse-item__head.collapsed {
    background: #F7F9FA;
}
.collapse-item--plain .collapse-item__head {
    padding: calc(15rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(40rem / 16);
    }
}
.collapse-item--plain .collapse-item__head__icon {
    font-size: calc(12rem / 16);
    right: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        right: calc(26rem / 16);
    }
}
.collapse-item--plain .collapse-item__body {
    padding: 0 calc(10rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(40rem / 16) calc(30rem / 16);
    }
}
.collapse-item--bordered {
    border: 1px solid #E7E7E7;
}

/* overview collapse */
.collapse-item--overview {
    background: none;
}
.collapse-item--overview .collapse-item__title {
    font-size: calc(20rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }
}
.collapse-item__title__icon {
    font-size: calc(18rem / 16);
    margin-right: calc(3rem / 16);
    vertical-align: -0.15em;

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
    }
}
.collapse-item--overview + .collapse-item--overview {
    margin-top: calc(25rem / 16);
    padding-top: calc(25rem / 16);
    border-top: 2px solid #979797;
}
.collapse-item__subtitle {
    font-size: calc(14rem / 16);
    line-height: calc(18/14);
}

@media screen and (max-width: 767px) {
    .container .collapse-item--lg,
    .container .collapse-item--ticket {
        margin-left: calc(-20rem / 16);
        margin-right: calc(-20rem / 16);
    }
}

.collapse-item .collapse-item__head__badge {
    width: calc(40rem/16);
    height:calc(40rem/16);
    font-size:calc(12rem/16);

    @media screen and (min-width: 768px) {
        width: calc(55rem / 16);
        height: calc(55rem / 16);
        font-size: calc(16rem / 16);
    }
}
