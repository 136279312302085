.footer {
    padding: calc(20rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0;
    }
}
.sitebyelements {
    width: calc(160rem / 16);
    height: auto;
}