.twitter-typeahead {
    width: 100%;
}
.tt-menu {
    width: 100%;
    background: #fff;
    border: 1px solid #f7f9fa;
}
.tt-selectable {
    padding: calc(2rem / 16) calc(10rem / 16);
    cursor: pointer;
}
.tt-suggestion.tt-selectable:hover {
    color: var(--color-primary);
}