.status {
    display: inline-block;
    border-radius: 50%;
    width: calc(24rem/16);
    height: calc(24rem/16);
    border: calc(3rem/16) solid #ffffff;
    box-shadow: 0 0 0 calc(1rem/16) #E7E7E7;
    vertical-align: -.4em;
}
.status--success{
    background-color: #B4F072;
}
.status--error, .status--danger {
    background-color: #EE2E22;
}
.status--progress, .status--warning {
    background-color: #ee9531;
}
.status--primary, .status--info {
    background-color: var(--color-primary);
}