.sidebar {
    border: calc(2rem / 16) solid #F7F9FA;
}
.is-affix .sidebar {
    max-height: calc(100vh - calc(100rem / 16));
    overflow-y: auto;
    overflow-x: hidden;
}
.sidebar__head {
    font-size: calc(20rem / 16);
    line-height: calc(25/24);
    padding: calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(24rem / 16);
    }
}
.sidebar__head__icon {
    margin-right: calc(6rem / 16);
    font-size: calc(24rem / 16);
    vertical-align: -.2em;

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
        margin-right: calc(10rem / 16);
    }
}
.sidebar__content {
    padding: calc(20rem / 16) calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(15rem / 16);
    }
}
.sidebar__content__title {
    font-size: calc(18rem / 16);
    line-height: calc(25/20);

    @media screen and (min-width: 1400px) {
        font-size: calc(20rem / 16);
    }
}
.sidebar__content__icon{
    font-size: calc(24rem / 16);
}
.sidebar__bottom {
    font-size: calc(20rem / 16);
    line-height: calc(25/24);
    padding: calc(20rem / 16) calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(24rem / 16);
    }
}
.sidebar__price-value {
    font-size: calc(18rem / 16);
    line-height: calc(26/22);

    @media screen and (min-width: 1400px) {
        font-size: calc(22rem / 16);
    }
}
.sidebar__hr{
    margin: calc(18rem / 16) 0;
    border-top: calc(2rem / 16) solid #979797;
}