.btn-green {
    background: var(--color-green);
    color:#fff;
}
.btn-green:hover {
    background: #20b663;
    color: #fff;
}
.btn-breakable {
    white-space: normal;
}
