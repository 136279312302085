.data-row + .data-row {
    margin-top: calc(8rem / 16);
}
.data-row.data-row--border-top {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 2px solid #979797;
}
.data-row__price-total {
    font-size: calc(22rem / 16);
}