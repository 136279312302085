.count-headline {
    font-size: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(36rem / 16);
    }
}
.count-headline__count {
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    padding: calc(4rem / 16);
    line-height: calc(34/36);

    @media screen and (min-width: 768px) {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
        padding: calc(5rem / 16);
    }
}