:root {
    --container-lg: calc(1333rem/16);
    --container-md: calc(1095rem/16);
}

.container.container {
    max-width: calc(1640rem/16);
    width: 100%;
}
.container.container-lg {
    max-width: var(--container-lg);
    margin: 0 auto;
}
.container.container-md {
    max-width: var(--container-md);
    margin: 0 auto;
}
@media screen and (max-width: 767px) {
    .container-break-xs {
        margin-left: -20px;
        margin-right: -20px;
    }
    .container-breadcrumb-xs {
        position: relative;
    }
    .container-breadcrumb-xs .breadcrumb {
        padding-right: calc(40rem/16);
        padding-bottom: calc(5rem/16);
    }
    .container-breadcrumb-xs:after {
        content: "";
        display: block;
        position: absolute;
        right: calc(20rem/16);
        top: 0;
        bottom: 0;
        width: calc(40rem/16);
        height: auto;
        pointer-events: none;
        background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff);
        z-index: 6;
    }
}
