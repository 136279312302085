@font-face {
    font-family: 'veneer';
    src: url('/static/fonts/veneer.woff2') format('woff2'),
    url('/static/fonts/veneer.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Cabin-Bold';
    src: url('/static/fonts/Cabin-bold.woff2') format('woff2'),
    url('/static/fonts/Cabin-bold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@media screen and (min-width: 768px) {
    @font-face {
        font-family: 'MatterhornClimb';
        src: url('/static/fonts/desktop/MatterhornClimb.woff2') format('woff2'),
        url('/static/fonts/desktop/MatterhornClimb.woff') format('woff');
        font-weight: normal;
        font-display: block;
    }
}
@media screen and (max-width: 767px) {
    @font-face {
        font-family: 'MatterhornClimb';
        src: url('/static/fonts/mobile/MatterhornClimb.woff2') format('woff2'),
        url('/static/fonts/mobile/MatterhornClimb.woff') format('woff');
        font-weight: normal;
        font-display: block;
    }
}
