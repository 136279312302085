.icon-link-box {
    box-shadow: calc(6rem / 16) calc(6rem / 16) calc(17rem / 16) 0 rgba(0,0,0,0.2);
    font-size: calc(12rem / 16);
    line-height: calc(18/16);
    color: var(--color-headline-default);
    font-family: var(--font-default);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(25/20);
        width: calc(200rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);

    }
}
.icon-link-box--variable-width {
    @media screen and (min-width: 768px) {
        width: auto;
    }
}
.icon-link-box__icon {
    font-size: calc(35rem / 16);
    color: #175284;

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
}

.icon-link-box__content {
    padding: calc(10rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(10rem / 16) calc(35rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(60rem / 16) calc(10rem / 16) calc(35rem / 16);
    }
}