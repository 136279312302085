.input-button:before,
.input-button:after {
    content: " ";
    display: table;
}
.input-button:after {
    clear: both;
}
.input-button__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.input-button {
    color: var(--color-text-default);
}
.input-button__button {
    display: block;
    font-size: calc(16rem / 16);
    line-height: calc(23/16);
    letter-spacing: calc(2rem / 16);
    padding: calc(10rem / 16) calc(32rem / 16) calc(8rem / 16);
    position: relative;
    cursor: pointer;
    color: currentColor;
    border: 1px solid currentColor;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
}
.input-button__input:checked ~ .input-button__button {
    background: var(--color-primary);
    border-color: var(--color-primary);
}
.input-button__input:checked ~ .input-button__button .input-button__button__text {
    color: #fff;
}
.has-error .input-button__button {
    border-color: var(--color-danger)
}