.cart-data__state {
    display: none;
}
.is-valid .cart-data__state {
    display: block;
}
.cart-data__edit {
    font-size: calc(20rem / 16);
    vertical-align: -0.2em;
    transition: opacity 200ms ease-in-out;
    position: absolute;
    right: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        right: calc(26rem / 16);
        font-size: calc(22rem / 16);
    }
}
.is-open.cart-data .cart-data__edit {
    opacity: 0;
    pointer-events: none;
}
.cart-data__close {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
}
.is-open.cart-data .cart-data__close {
    opacity: 1;
    pointer-events: auto;
}
.cart-data-total__value {
    font-size: calc(30rem / 16);
}